nav {
    border-bottom: 1px solid #dee2e6 !important;
}

#my-nav {
    .side-nav-content-grid {
        display: grid;
        grid-template-columns: 400px 1fr;
    }



    .nav {
        padding: 1rem 2rem;
        border-bottom: 1px solid #dee2e6 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .icon-name {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: left;
        font-size: large;
    }

    .brand-name {
        white-space: normal;
        text-align: center;
        word-break: break-all;
        text-decoration: none;
        font-weight: 600;
        text-wrap: nowrap;
    }
}

@media only screen and (max-width: 850px) {
    #my-nav {
        .icon-name {
            gap: 0.5rem;
        }

        .brand-name {
            font-size: small;
        }


    }

    .nav-search {
        display: none !important;
    }
}