.cart-footer {
    background-color: lightgray;
    position: fixed;
    bottom: 0px;
    height: 7rem;
    width: calc(100vw - 300px);
    display: block;

}

.delivery-addresses {
    margin-bottom: 10rem;
}

.amout-price-spilt {
    margin: 0px 5rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.check-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-item {
    border: 1px solid lightgray;
}

.no-items-list {
    color: rgb(68, 49, 145)
}

.ad-body {
    padding: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;


}

.add-address {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-actions {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 850px) {

    .ad-body {
        padding: 0rem !important;



    }
}