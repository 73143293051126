* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.colort-purple {
    color: rgb(68, 49, 145);
}

a {
    text-decoration: none;
    color: #000;
}

.side-nav-content-grid {
    display: grid;
    grid-template-columns: 300px 1fr;
}

.content {
    height: calc(100vh - 67px);
    overflow: scroll;
}

@media only screen and (max-width: 850px) {
    .side-nav-content-grid {
        grid-template-columns: 1fr;
    }
}