.welcom-section {
    background: linear-gradient(0deg, rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3)), url("./Cover\ Test1.png");
    background: linear-gradient(to bottom, rgba(205, 206, 202, 0.52), rgba(117, 19, 93, 0.73)), url("./Cover\ Test1.png");
    background-size: cover;
    background-position: center center;

    padding: 2rem;
}

.links {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 3rem;
    color: #fff !important;
}

.header {
    color: #fff;
}

.card-text {
    height: 60px;
}

.price {
    color: green;
    font-weight: bold;
    font-size: small;
}

.category-header {
    color: rgb(68, 49, 145);
    white-space: normal;
    word-break: break-all;
    text-decoration: none;
    font-weight: 600;
    text-wrap: nowrap;
    font-size: larger;
}

.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 175px;

    img {
        max-height: 175px;
        max-width: 175px;
        padding-top: 10px;
    }
}

.card-text {
    font-size: small;
}

.products {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: left;


    .card {
        width: 15rem !important;
    }
}

@media only screen and (max-width: 850px) {
    .products {
        gap: 0.4rem;
        justify-content: center;

        .card {
            width: 12rem !important;
        }

    }
}