.side-nav {
    display: none;
    height: calc(100vh - 67px);
    border-right: 1px solid #dee2e6 !important;

    .item {
        margin-bottom: .5rem;
        padding: .3rem;
        user-select: none;
        cursor: pointer;
    }

    .header {
        color: rgb(68, 49, 145);
        font-weight: bolder;
        font-size: small;
    }

    .item:hover {
        background-color: rgb(221, 221, 221);
    }

    .selected {
        color: rgb(255, 255, 255);
        background-color: rgb(68, 49, 145);
    }

    .selected:hover {
        color: #333;
    }
}

@media only screen and (min-width: 850px) {
    .side-nav {
        display: block;
    }
}