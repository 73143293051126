.price-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;


    .price {
        text-wrap: nowrap;
    }
}

@media only screen and (max-width: 850px) {
    .price-section {
        display: flex;

    }
}