.new-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;

    img {
        max-height: 300px;
        max-width: 300px;
        padding-top: 10px;
    }
}