.login-header {
    background-color: rgb(92, 49, 145);
    color: #fff;
}

.form-item {
    margin-bottom: 2rem;
}

.forgot-password {
    font-size: small;
    color: rgb(92, 49, 145);
    cursor: pointer;
}

.register-text {
    font-size: small;

    span {
        color: rgb(92, 49, 145);
        text-decoration: underline;
        cursor: pointer;
    }
}