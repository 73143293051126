.hand {
    cursor: pointer;
}

.no-select {
    user-select: none;
}

.add-to-cart-text {
    cursor: pointer;
    user-select: none;
}

.my-btn:hover {
    background-color: #0d6efd;
    color: #fff;
}