.product-backbutton-grid {
    display: grid;
    grid-template-columns: 30px 1fr;
}

.description-container {
    width: 60%;
    margin: 0px auto;

    .price {
        color: green;
    }
}

.back-button-desktop {
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
        display: none;
    }
}

.back-button-mobile {
    display: none;
    width: 60%;
    margin: 0px auto;

    i {
        margin-right: 0.5rem;
    }

}

.add-to-cart {
    text-align: right;
}

.info {
    font-size: smaller;
    text-decoration: underline;
    margin-right: 1rem;
    user-select: none;
}

.related-product-container {
    margin: 5px auto;
}

@media only screen and (max-width: 850px) {
    .table {
        background-color: red !important;
    }

    .back-button-desktop {
        display: none;
    }

    .back-button-mobile {
        display: block;
    }

    .description-container {
        width: 90%;
    }

    .product-backbutton-grid {
        grid-template-columns: 1fr;
    }
}